export default {
  //接口基地址

  //代理及基地址
  BASE_PATH: "",

  //后台接口
  REGISTRY: "/api/user/register",
  LOGIN: "/api/user/login",
  LOGOUT: "/api/user/logout",
  INFO: "/api/user/info",
  MODIFY_PWD: "/api/user/password/update",
  MODIFY_BASIC: "/api/user/info/update",
  RESET_PWD: "/api/user/password/recovery",

  QUERY_ALL_ACTIVE_PROJECT: "/api/project/all",
  BUY_ORGAN_FILE: "/api/project/organFile/buy",

  QUERY_USER_QUALIFICATION: "/api/user/qualification/all",
  SAVE_USER_QUALIFICATION: "/api/user/qualification/save",
  DELETE_USER_QUALIFICATION: "/api/user/qualification/delete/",

  DOWN_TEMPLATE: "/api/config/{key}/value",

  GET_OSS_TOKEN: "/api/aliyun/oss/credential",

  PAGE_ORDER: "/api/order/page",
  QUERY_ORDER_RESULT: "/api/pay/queryResult/",

  CANCEL_ORDER: "/api/order/{orderNo}/cancel",
  BIND_ORDER_QUALIFICATION: "/api/order/bindQualificationInfo",
  GET_ORGAN_FILE_URL: "/api/order/{orderNo}/getOrganFileUrl",

  GET_PAGE_CONFIG: "/api/config/websiteConfig",
  BIND_AI_KEY: "/api/user/robotKey/bind",
  GET_VERIFY_CODE: "/api/user/sendSmsVerifyCode",
  QUERY_VERIFY_DOCUMENT_GENERATE_RESULT:
    "/api/project/biddingTemplate/queryDocumentGenerateResult",
};
