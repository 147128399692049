<template>
  <div class="change-password">
    <div class="change-password-container">
      <div class="change-password-header">
        <div class="change-password-logo"></div>
      </div>
      <a-form
        layout="vertical"
        :model="formState"
        @finish="handleFinish"
        hideRequiredMark
        @finishFailed="handleFinishFailed"
      >
        <a-form-item
          name="pwd"
          :rules="[
            { required: true, message: '请输入新密码' },
            { message: '请输入至少6位字符', min: 6 },
          ]"
          label="新密码"
        >
          <a-input
            v-model:value="formState.pwd"
            type="password"
            placeholder="请输入新密码"
          >
            <template #prefix
              ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
        </a-form-item>
        <a-form-item
          name="pwdConfirm"
          :rules="[
            { required: true, message: '请再次输入新密码' },
            {
              message: '两次输入的密码不一致',
              pattern: new RegExp('^' + formState.pwd + '$'),
            },
          ]"
          label="请再次输入新密码"
        >
          <a-input
            v-model:value="formState.pwdConfirm"
            type="password"
            placeholder="请再次输入新密码"
          >
            <template #prefix
              ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
        </a-form-item>
        <a-form-item
          name="oldPwd"
          :rules="[{ required: true, message: '请输入旧密码' }]"
          label="请输入旧密码"
        >
          <a-input
            v-model:value="formState.oldPwd"
            type="password"
            placeholder="请输入旧密码"
          >
            <template #prefix
              ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button
            class="registry-button"
            type="primary"
            html-type="submit"
          >
            修改密码
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";
// import {inject} from "vue"
import api from "@/api/api";
import * as common from "@/api/common";
import http from "@/api/http";

// const http = inject('$http')
const router = useRouter();

const formState = reactive({
  userPhone: "",
  pwd: "",
  pwdConfirm: "",
  oldPwd: "",
});

const checkSame = () => {
  if (formState.pwd === formState.oldPwd) {
    common.showError("新密码不能与旧密码相同");
    return false;
  }
  return true;
};

const handleFinish = (values) => {
  console.log(values, formState);
  if (!checkSame()) {
    return;
  }
  const params = {
    userPhone: values.userPhone,
    newPwd: values.pwd,
    oldPwd: values.oldPwd,
  };
  http
    .post(api.MODIFY_PWD, params, {
      headers: {},
    })
    .then((response) => {
      // 处理响应
      console.log(response.data);
      let code = response.data.code;
      if (code == 200) {
        common.showSuccess("修改成功，请登录");
        router.push({
          path: "/login",
        });
      }
    });
};
const handleFinishFailed = (errors) => {
  console.log(errors);
};
</script>

<style>
.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.change-password-container {
  width: 440px;
}
.change-password-header {
  display: flex;
  justify-content: center;
  margin: 40px 0 20px;
}
.change-password-logo {
  width: 200px;
  height: 200px;
  background: url("~@/assets/lock-big.png") no-repeat, none;
}
.registry-button {
  width: 100%;
}
.extra {
  line-height: 36px;
}
.sms-btn {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #3860f4;
  text-align: left;
  font-style: normal;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background: rgba(56, 96, 244, 0.07);
  border-radius: 2px;
}
</style>
