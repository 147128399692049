<template>
  <div class="api-block">
    <div class="api-block-container">
      <div class="api-block-header">
        <div class="api-block-logo"></div>
      </div>
      <div class="api-block-line">
        <div class="api-block-line-label">AI报价软件秘钥</div>
        <div class="api-block-line-form">
          <a-input
            v-model:value="newKey"
            placeholder="请输入AI报价软件秘钥"
            :disabled="!isChange"
          />
          <div
            v-if="!isChange"
            class="api-btn-wrapper"
          >
            <a-button
              type="primary"
              html-type="submit"
              class="api-block-line-form-btn"
              @click="handleChange()"
            >
              换绑
            </a-button>
          </div>
          <div
            v-else
            class="api-btn-wrapper"
          >
            <a-button
              html-type="submit"
              class="api-block-line-form-btn"
              @click="onCancel"
            >
              取消
            </a-button>
            <a-button
              type="primary"
              html-type="submit"
              class="api-block-line-form-btn"
              @click="handleBind"
            >
              保存
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import api from "@/api/api";
import * as common from "@/api/common";
import http from "@/api/http";
import { message } from "ant-design-vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
// import {inject} from "vue"
const userInfo = common.getUserInfo();
// const http = inject('$http')
const router = useRouter();
const isChange = ref(false);
const newKey = ref(userInfo?.robotKey);
const oldKey = ref("");

const handleChange = () => {
  oldKey.value = newKey.value;
  isChange.value = true;
};

const onCancel = () => {
  newKey.value = oldKey.value;
  isChange.value = false;
};

const handleBind = () => {
  http
    .post(
      api.BIND_AI_KEY,
      {
        robotKey: newKey.value,
      },
      {
        headers: {},
      }
    )
    .then((response) => {
      // 处理响应
      console.log(response.data);
      let code = response.data.code;
      if (code == 200) {
        message.success("绑定成功");
        isChange.value = false;
      }
    });
};
</script>

<style>
.api-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.api-block-container {
  width: 440px;
}
.api-block-header {
  display: flex;
  justify-content: center;
  margin: 40px 0 20px;
  flex-direction: column;
  align-items: center;
}
.api-block-logo {
  width: 200px;
  height: 200px;
  background: url("~@/assets/apikey.png") no-repeat, none;
}

.api-btn-wrapper {
  width: 240px;
  display: flex;
  align-items: center;
}
.api-block-line {
}

.api-block-line-label {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  margin-bottom: 30px;
}

.api-block-line-form {
  display: flex;
  align-items: center;
}

.api-block-line-form-btn {
  margin-left: 12px;
}

.registry-button {
  width: 100%;
}
.extra {
  line-height: 36px;
}
</style>
