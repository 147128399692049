<template>
  <div class="list-block">
    <div class="list-block-title">
      {{ item.day }}
      <div
        @click="handleHide"
        style="cursor: pointer; margin-left: 8px"
      >
        <UpOutlined v-if="!isHide" />
        <DownOutlined v-else />
      </div>
    </div>
    <div
      class="list-block-content"
      v-if="!isHide"
    >
      <div
        class="list-block-content-item"
        v-for="i in item.projectList"
        :key="i.bizIdProject"
      >
        <div class="list-block-content-item-left">
          <img
            style="height: 73px; width: 73px; border-radius: 50%"
            src="@/assets/list-img.png"
            alt=""
          />
        </div>
        <div class="list-block-content-item-right">
          <a-tooltip>
            <template #title>{{ i.projectName }}</template>
            <div class="list-block-content-item-right-title">
              {{ i.projectName }}
            </div>
          </a-tooltip>

          <div class="list-block-content-item-right-tag">
            {{ i.province }}{{ i.city }}
          </div>
          <div class="list-block-content-item-right-time">
            开标时间:{{
              i.bidDeadline
                ? moment(i.bidDeadline).format("YYYY-MM-DD HH:mm")
                : "-"
            }}
          </div>
          <div class="list-block-content-item-right-info">
            <InfoCircleFilled class="list-block-content-item-right-info-icon" />
            提示：所有资料仅供内部培训参考使用，请勿用于其他用途
          </div>
        </div>
        <div
          class="list-block-content-item-btn"
          @click="openModal(i)"
        >
          免费获取
        </div>
      </div>
    </div>
  </div>
  <ContentModal
    :visible="modalInfo.visible"
    :id="modalInfo.id"
    :hideModal="hideModal"
  />
</template>

<script setup>
import * as common from "@/api/common";
import moment from "moment";
import { defineProps, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import ContentModal from "./ContentModal.vue";

const router = useRouter();

const userInfo = common.getUserInfo();

const bizIdUser = ref(userInfo?.bizIdUser);

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const isHide = ref(false);
const modalInfo = reactive({
  id: "",
  visible: false,
});

const openModal = (i) => {
  const id = i.bizIdProject;
  if (!bizIdUser.value) {
    router.push({
      path: "/login",
    });
    return;
  }
  modalInfo.id = id;
  modalInfo.visible = true;
};

const hideModal = () => {
  modalInfo.id = "";
  modalInfo.visible = false;
};

const handleHide = () => {
  isHide.value = !isHide.value;
};
</script>

<style scoped>
.list-block {
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
}
.list-block-title {
  font-family: HelveticaNeue;
  font-size: 14px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  display: flex;
}

.list-block-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.list-block-content-item {
  width: 49.5%;
  height: 160px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-top: 12px;
  position: relative;
}
.list-block-content-item-btn {
  height: 32px;
  padding: 0 16px;
  border: 1px solid #1677ff;
  display: flex;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #3860f4;
  line-height: 22px;
  font-style: normal;
  position: absolute;
  right: 16px;
  cursor: pointer;
}
.list-block-content-item-left {
  height: 73px;
  width: 73px;
  border-radius: 50%;
}
.list-block-content-item-right {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list-block-content-item-right-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 10, 26, 0.89);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
  flex: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
.list-block-content-item-right-tag {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  width: fit-content;
  font-size: 12px;
  color: #3860f4;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  padding: 0 8px;
  background: rgba(37, 103, 241, 0.11);
  border-radius: 4px;
  margin-bottom: 8px;
}
.list-block-content-item-right-time {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}

.list-block-content-item-right-info {
  background: #fff1f0;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #f5222d;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  height: 28px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
}

.list-block-content-item-right-info-icon {
  margin-right: 4px;
}
</style>
