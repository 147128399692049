<template>
  <LoginLayout>
    <div class="reset-password">
      <div class="reset-password-container">
        <div class="reset-password-header">
          <div class="reset-password-logo"></div>
          忘记密码
        </div>
        <a-form
          layout="vertical"
          :model="formState"
          @finish="handleFinish"
          hideRequiredMark
          @finishFailed="handleFinishFailed"
        >
          <a-form-item
            name="userPhone"
            :rules="[
              { required: true, message: '请输入手机号' },
              {
                message: '请输入正确的手机号',
                pattern: new RegExp(/^1[3456789]\d{9}$/),
              },
            ]"
            label="请输入手机号"
          >
            <a-input
              v-model:value="formState.userPhone"
              placeholder="手机号"
            >
              <template #prefix
                ><PhoneOutlined style="color: rgba(0, 0, 0, 0.25)"
              /></template>
            </a-input>
          </a-form-item>

          <a-form-item
            name="verifyCode"
            :rules="[{ required: true, message: '请输入验证码' }]"
            label="验证码"
          >
            <a-input-group compact>
              <a-input
                v-model:value="formState.verifyCode"
                placeholder="验证码"
                style="width: calc(100% - 120px)"
              >
                <template #prefix><VerifiedOutlined /></template>
              </a-input>
              <a-button
                style="width: 110px; margin-left: 10px"
                type="primary"
                class="sms-btn"
                @click="getVerifyCode"
                :disabled="verifyTime > 0"
              >
                {{ verifyTime > 0 ? verifyTime + "秒后重发" : "获取验证码" }}
              </a-button>
            </a-input-group>
          </a-form-item>
          <a-form-item
            name="pwd"
            :rules="[
              { required: true, message: '请输入新密码' },
              { message: '请输入至少6位字符', min: 6 },
            ]"
            label="新密码"
          >
            <a-input
              v-model:value="formState.pwd"
              type="password"
              placeholder="请输入新密码"
            >
              <template #prefix
                ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
              /></template>
            </a-input>
          </a-form-item>
          <a-form-item
            name="pwdConfirm"
            :rules="[
              { required: true, message: '请再次输入新密码' },
              {
                message: '两次输入的密码不一致',
                pattern: new RegExp('^' + formState.pwd + '$'),
              },
            ]"
            label="请再次输入新密码"
          >
            <a-input
              v-model:value="formState.pwdConfirm"
              type="password"
              placeholder="请再次输入新密码"
            >
              <template #prefix
                ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
              /></template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button
              class="registry-button"
              type="primary"
              html-type="submit"
            >
              修改密码
            </a-button>
            <div
              class="extra"
              style="float: right"
              @click="goLogin"
            >
              <a href="">使用已有账号登录</a>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </LoginLayout>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
// import {inject} from "vue"
import api from "@/api/api";
import * as common from "@/api/common";
import http from "@/api/http";
import LoginLayout from "@/components/LoginLayout.vue";

const router = useRouter();
const verifyTime = ref(0);
const formState = reactive({
  userPhone: "",
  pwd: "",
  pwdConfirm: "",
  verifyCode: "",
});

const getVerifyCode = () => {
  if (!formState.userPhone) {
    common.showError("请输入手机号");
    return;
  }
  verifyTime.value = 60;
  http
    .post(api.GET_VERIFY_CODE, {
      userPhone: formState.userPhone,
      scene: "USER_PASSWORD_RECOVERY",
    })
    .then((response) => {
      console.log(response);
      common.showSuccess("验证码已发送");
    });
  const interval = setInterval(() => {
    verifyTime.value = verifyTime.value - 1;
    if (verifyTime.value <= 0) {
      verifyTime.value = 0;
      clearInterval(interval);
    }
  }, 1000);
};

const handleFinish = (values) => {
  console.log(values, formState);
  const params = {
    userPhone: values.userPhone,
    newPwd: values.pwd,
    verifyCode: values.verifyCode,
  };
  http
    .post(api.RESET_PWD, params, {
      headers: {},
    })
    .then((response) => {
      // 处理响应
      console.log(response.data);
      let code = response.data.code;
      if (code == 200) {
        common.showSuccess("修改成功，请登录");
        router.push({
          path: "/login",
        });
      }
    });
};
const handleFinishFailed = (errors) => {
  console.log(errors);
};

const goLogin = (e) => {
  e.preventDefault();
  router.push({
    path: "/login",
  });
};
</script>

<style>
.reset-password {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reset-password-container {
  width: 100%;
}
.reset-password-header {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #333333;
  line-height: 40px;
  text-align: left;
  font-style: normal;
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}
.reset-password-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 16px;
  background: url("~@/assets/lock.png") no-repeat, none;
}
.registry-button {
  width: 100%;
}
.extra {
  line-height: 36px;
}
.sms-btn {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #3860f4;
  text-align: left;
  font-style: normal;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background: rgba(56, 96, 244, 0.07);
  border-radius: 2px;
}
</style>
