import { message } from 'ant-design-vue';
import http from "./http"

export function showError(msg){
    message.error(msg);
}

export function showInfo(msg){
    message.info(msg);
}

export function showSuccess(msg){
    message.success(msg);
}

export function getUserInfo(){
    let json = localStorage.getItem("userInfo");
    if(!json){
        return null;
    }else{
        return JSON.parse(json);
    }
}

export function getBizIdUser(){
    let userInfo = getUserInfo();
    if(!userInfo){
        return null;
    }else{
        return userInfo.bizIdUser;
    }
} 

