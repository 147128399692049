<template>
  <a-modal
    visible
    @Cancel="hideModal"
  >
    <template #title>
      <div class="modal-title">
        {{ downloadUrl ? "报价模板" : "生成您的专属报价模板" }}
      </div>
    </template>
    <div
      class="modal-content"
      v-if="!downloadUrl"
    >
      点击“生成”按钮，生成您的专属报价模板，然后再结合“易标AI报价软件”的使用，可以AI自动制作您的专属报价文件。关于“易标AI报价软件”的下载和使用，请移步页面上方的“软件&教程”来查看
    </div>
    <a-progress
      :percent="percent"
      status="active"
      v-if="isStart || percent === 100"
    />
    <div v-if="downloadUrl">
      <a @click="onDownload">下载</a>
    </div>
    <template #footer>
      <div class="modal-footer">
        <a-button
          type="primary"
          @click="start"
          :disabled="isStart"
          v-if="!downloadUrl"
          >生成</a-button
        >
        <a-button
          @click="onDownload"
          v-if="downloadUrl"
          >下载</a-button
        >
        <a-button @click="hideModal">取消</a-button>
      </div>
    </template>
  </a-modal>
</template>
<script setup>
import api from "@/api/api";
import http from "@/api/http";
import { message } from "ant-design-vue";
import { defineProps, ref } from "vue";
const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  hideModal: {
    type: Function,
    default: () => {},
  },
});

const isStart = ref(false);
const percent = ref(0);
const downloadUrl = ref("");
const bizIdFileGenerateRecord = ref(null);
let interval = null;
// 在20秒内生成，进度随机从0到100

const queryVerifyDocumentGenerateResult = (cb) => {
  console.log(bizIdFileGenerateRecord.value);
  http
    .get(api.QUERY_VERIFY_DOCUMENT_GENERATE_RESULT, {
      params: {
        bizIdFileGenerateRecord: bizIdFileGenerateRecord.value,
      },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.data) {
        if (
          response.data.code == 200 &&
          ["SUCCESS", "FAIL"].includes(response.data.data.status)
        ) {
          if (response.data.data.status == "SUCCESS") {
            message.success("生成验证文件成功");
            console.log(response.data.data.templateFileUrl);
            downloadUrl.value = response.data.data.templateFileUrl;
          } else {
            message.error("生成验证文件失败");
          }
          cb && cb();
        }
      }
    });
};

const getDownloadUrl = (cb) => {
  http
    .post(`/api/project/${props.id}/biddingTemplate/generateDocument`, {})
    .then((response) => {
      console.log(response, response.data.data);
      if (response.data.code === 200 && response.data.data) {
        bizIdFileGenerateRecord.value = response.data.data;
        console.log("downloadUrl:", downloadUrl.value);
        cb && cb();
      }
    });
};

const onDownload = () => {
  window.open(downloadUrl.value);
};

const start = () => {
  getDownloadUrl(() => {
    isStart.value = true;
    percent.value = 0;
    interval = setInterval(() => {
      percent.value = percent.value + Math.floor(Math.random() * 10);
      queryVerifyDocumentGenerateResult(() => {
        percent.value = 100;
        isStart.value = false;
        clearInterval(interval);
      });
      if (percent.value >= 100) {
        percent.value = 100;
        isStart.value = false;
        clearInterval(interval);
      }
    }, 5000);
    setTimeout(() => {
      if (interval) {
        clearInterval(interval);
        percent.value = 100;
        isStart.value = false;
      }
    }, 60000);
  });
};
</script>
<style scoped></style>
