<template>
  <div class="order-list-block">
    <div class="order-list-block-header">
      <div class="order-list-block-notice">
        <div class="order-list-block-notice-icon">
          <NotificationOutlined />
          平台公告
        </div>
        <div class="order-list-block-notice-content">
          {{ sysInfo || "暂无公告" }}
        </div>
      </div>
      <div class="order-list-block-search">
        <a-input
          v-model:value="keywords"
          placeholder="请输入项目名称"
          size="large"
          @pressEnter="queryProject(keywords)"
        >
          <template #suffix>
            <SearchOutlined @click="queryProject(keywords)" />
          </template>
        </a-input>
      </div>
    </div>
    <div class="order-list-block-content">
      <div class="order-list-block-content-wrapper">
        <div class="order-list-block-content-inner">
          <ListContent
            v-for="i in listData"
            :key="i.day"
            :item="i"
          />
        </div>
      </div>
    </div>
    <div class="order-list-block-footer">
      <a
        target="_blank"
        style="margin-left: 8px"
        href="http://beian.miit.gov.cn/"
        >赣ICP备2024050482号</a
      >
    </div>
  </div>
</template>

<script setup>
import api from "@/api/api";
import http from "@/api/http";
import ListContent from "@/components/ListContent.vue";
import { ref } from "vue";

const systemAnnouncement = localStorage.getItem("systemAnnouncement");

const sysInfo = ref(systemAnnouncement);
const listData = ref([]);
const keywords = ref("");

const queryProject = (keyword) => {
  console.log(keyword);
  let values = {
    projectName: keyword,
  };

  http
    .get(
      api.QUERY_ALL_ACTIVE_PROJECT,
      {
        params: values,
      },
      {
        headers: {},
      }
    )
    .then((response) => {
      // 处理响应
      console.log(response.data);
      let code = response.data.code;
      let dayProjectList = response.data?.data?.dayProjectList || [];
      if (code == 200) {
        console.log(dayProjectList);
        listData.value = dayProjectList || [];
      }
    });
};
queryProject();
</script>

<style scoped>
.order-list-block {
  padding: 20px 20px 4px;
  background: #f5f8ff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.order-list-block-header {
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.order-list-block-notice {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 14px;
  background: linear-gradient(
    180deg,
    #d9e1ff 0%,
    #e1e8ff 0%,
    rgba(255, 255, 255, 0.99) 100%
  );
  border-radius: 12px;
}
.order-list-block-notice-icon {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}

.order-list-block-notice-content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-left: 12px;
}

.order-list-block-search {
  width: 60%;
  padding: 16px 0;
}

.order-list-block-content {
  background: #ffffff;
  border-radius: 12px;
  padding: 16px;
  flex: 1;
  overflow: hidden;
}

.order-list-block-content-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 16px;
}

.order-list-block-content-wrapper {
  height: 100%;
  overflow-y: overlay;
}
.order-list-block-content-inner {
  padding-bottom: 12px;
}

.order-list-block-footer {
  margin-top: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  text-align: center;
  font-style: normal;
}
</style>
