import axios from 'axios'
import api from '@/api/api.js';
import { message } from 'ant-design-vue';

axios.defaults.baseURL = api.BASE_PATH

// 创建一个 axios 实例
const http = axios.create({
	timeout: 60000, // 请求超时时间毫秒
	withCredentials: false, // 异步请求携带cookie
	headers: {
		// 设置后端需要的传参类型
		'Content-Type': 'application/json',
	},
})
 
//拦截器 请求拦截 
http.interceptors.request.use(config => {
  //做点什么----根据后端约定执行相关 这里是判断开发/线上环境 存储添加token
  let token = localStorage.getItem('token');

  //判断是否存在token，根据后端约定在header的authorization都加上token 
  if(token){
    config.headers.authorization = token
  }
  //根据后端约定执行相关 结束
 
  return config;
}, error => {
  console.log(error)
  return Promise.reject(error);
});
 
//拦截器 响应拦截
http.interceptors.response.use(response => {
  console.log(response)
  //做点什么----根据后端约定状态判断执行 这里是判断状态移除token
  if(response.data.code===20_004){
    localStorage.removeItem('token');
    message.error(response.data.msg)
    // 跳转登录页 TODO
  }else if(response.data.code !== 200){
    message.error(response.data.msg)
    return response
  }
  //根据后端约定状态判断执行 结束
 
  //可将 return response ↑放在做点什么中↑
  return response
}, error =>{
  console.log(error)
  message.error(error)
  return Promise.reject(error)
});
 
export default http