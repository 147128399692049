<template>

  <!-- <router-view></router-view> -->

  <a-config-provider :locale="zh_CN">
    <router-view></router-view>
  </a-config-provider>


</template>
<script>
import { defineComponent } from "vue";
// import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import zh_CN from 'ant-design-vue/es/locale/zh_CN' 
export default defineComponent({
  setup() {
    return {
      zh_CN,
    };
  },
});
</script>