<template>
  <div class="support-block">
    <div class="support-block-item">
      <div class="support-block-item-line">
        <div class="support-block-item-line-label">使用教程：</div>
        <div
          class="support-block-item-line-value"
          @click="handleTutorial"
        >
          点此跳转
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            class="support-block-item-line-value-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>编组 22</title>
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="软件&amp;教程"
                transform="translate(-1189.000000, -87.000000)"
              >
                <g
                  id="编组-20"
                  transform="translate(1189.000000, 87.000000)"
                >
                  <rect
                    id="矩形"
                    fill="#D8D8D8"
                    opacity="0"
                    transform="translate(8.000000, 8.000000) scale(-1, 1) translate(-8.000000, -8.000000) "
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                  ></rect>
                  <path
                    d="M7.75846682,8.92393891 C7.47651089,8.85318846 7.18431386,8.81680136 6.88673822,8.81680136 C4.91549149,8.81680136 3.31748764,10.4148052 3.31748764,12.3860519 C3.31748764,12.6872541 3.35473043,12.9833085 3.42761331,13.2695564 C3.4683196,13.4294886 3.3716812,13.5921372 3.21174907,13.632857 C3.14686344,13.64948 3.07826698,13.643723 3.01705971,13.6165175 C2.95854132,13.5920955 2.90935816,13.5496129 2.87668616,13.4952679 C2.59467076,13.0280208 2.37672912,12.5249921 2.22871314,11.9996885 C2.07748729,11.4627512 2,10.9045031 2,10.3362313 C2,7.0753136 4.54878171,4.40969655 7.76285392,4.2228442 C7.81683017,4.21972026 7.92618164,4.21837561 8.09092193,4.21883741 C8.24093895,4.21925846 8.36290842,4.09798169 8.36332947,3.94719047 L8.36332947,2.65720709 C8.36332947,2.40570277 8.65507828,2.26670103 8.85039243,2.42516627 L13.8894431,6.51345266 C14.0368523,6.63303164 14.0368523,6.8579553 13.8894431,6.97753428 L8.85039243,11.0658207 C8.65507828,11.2242859 8.36332947,11.0852842 8.36332947,10.8337799 L8.36332947,9.41503582 C8.3633284,9.24403565 8.25655838,9.09122807 8.09598814,9.03242111 C7.95839897,8.98203061 7.84588281,8.94586082 7.75846682,8.92393891 Z"
                    id="路径"
                    fill="#3860F4"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div class="support-block-item">
      <div class="support-block-item-line">
        <div class="support-block-item-line-label">AI报价软件：</div>
        <div
          class="support-block-item-line-value"
          @click="handleDownload"
        >
          点此下载
          <DownloadOutlined class="support-block-item-line-value-icon" />
        </div>
      </div>
      <div class="support-block-item-line-desc"></div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps({
  tutorialUrl: {
    type: String,
    default: "",
  },
  softwareDownloadUrl: {
    type: String,
    default: "",
  },
});

const handleDownload = () => {
  window.open(props.softwareDownloadUrl);
};

const handleTutorial = () => {
  window.open(props.tutorialUrl);
};
</script>
<style scoped>
.support-block {
  width: 400px;
  height: 100%;
  background-image: url("~@/assets/support-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}
.support-block-item {
  padding: 24px 24px;
}
.support-block-item:first-child {
  border-bottom: 1px solid #e8e8e8;
}
.support-block-item-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.support-block-item-line-label {
  min-width: 70px;
  text-align: right;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  font-style: normal;
}
.support-block-item-line-value {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #3860f4;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.support-block-item-line-value-icon {
  margin-left: 4px;
}
.support-block-item-line-desc {
  width: 363px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
</style>
