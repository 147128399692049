<template>
  <div class="userinfo-block">
    <div class="userinfo-block-container">
      <div class="userinfo-block-header">
        <div class="userinfo-block-logo"></div>
        <div class="userinfo-block-name">{{ userInfoState.userName }}</div>
        <div class="userinfo-block-desc">
          注册时间:{{ userInfoState.registerTime }}
        </div>
      </div>
      <a-form
        layout="vertical"
        :model="formState"
        @finish="handleFinish"
        hideRequiredMark
        @finishFailed="handleFinishFailed"
      >
        <a-form-item
          name="userName"
          :rules="[{ required: true, message: '请输入用户昵称' }]"
          label="请输入用户名"
        >
          <a-input
            v-model:value="formState.userName"
            placeholder="用户名"
          >
            <template #prefix
              ><PhoneOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button
            class="registry-button"
            type="primary"
            html-type="submit"
          >
            保存
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import api from "@/api/api";
import * as common from "@/api/common";
import http from "@/api/http";
import { reactive } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const userInfo = common.getUserInfo();
const userInfoState = reactive({
  userName: userInfo?.userName,
  userPhone: userInfo?.userPhone,
  registerTime: userInfo?.registerTime,
});

const formState = reactive({
  userName: userInfo?.userName,
  userPhone: userInfo?.userPhone,
});

const handleFinish = (values) => {
  console.log(values, formState);
  const params = {
    userPhone: values.userPhone,
    userName: values.userName,
  };
  http
    .post(api.MODIFY_BASIC, params, {
      headers: {},
    })
    .then((response) => {
      // 处理响应
      console.log(response.data);
      let code = response.data.code;
      if (code == 200) {
        common.showSuccess("修改成功");
        window.location.reload();
      }
    });
};
const handleFinishFailed = (errors) => {
  console.log(errors);
};
</script>

<style>
.userinfo-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userinfo-block-container {
  width: 440px;
}
.userinfo-block-header {
  display: flex;
  justify-content: center;
  margin: 40px 0 20px;
  flex-direction: column;
  align-items: center;
}
.userinfo-block-logo {
  width: 200px;
  height: 200px;
  background: url("~@/assets/user-big.png") no-repeat, none;
}

.userinfo-block-name {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 24px;
}

.userinfo-block-desc {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 26px;
}

.registry-button {
  width: 100%;
}
.extra {
  line-height: 36px;
}
.sms-btn {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #3860f4;
  text-align: left;
  font-style: normal;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background: rgba(56, 96, 244, 0.07);
  border-radius: 2px;
}
</style>
