import * as Icons from "@ant-design/icons-vue";
import { nextTick } from "@vue/runtime-core";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import { createApp } from "vue";
import { createRouter } from "vue-router";
import App from "./App.vue";

// import HelloWorld from './components/HelloWorld.vue'
import ApiKey from "./views/ApiKey.vue";
import ChangePassWord from "./views/ChangePassWord.vue";
import LoginView from "./views/LoginView.vue";
import MainView from "./views/MainView.vue";
import OrderList from "./views/OrderList.vue";
import RegistryView from "./views/RegistryView.vue";
import UserInfo from "./views/UserInfo.vue";

import * as api from "@/api/api";
import * as common from "@/api/common";
import * as http from "@/api/http";
import { createWebHistory } from "vue-router";
import ResetPassWord from "./views/ResetPassWord.vue";

const routes = [
  // { path: "/new_login", component: NewLoginView, name: "new_login" },
  {
    component: MainView,
    path: "/",
    children: [
      {
        path: "user-info",
        component: UserInfo,
      },
      {
        path: "ai",
        component: ApiKey,
      },
      {
        path: "change-pwd",
        component: ChangePassWord,
      },
      {
        path: "",
        component: OrderList,
      },
    ],
  },
  { path: "/login", component: LoginView, name: "login" },
  { path: "/registry", component: RegistryView, name: "registry" },
  { path: "/reset-pwd", component: ResetPassWord, name: "reset-pwd" },
  // { path: "/order", component: OrderView, name: "order" },
  // // { path: '/personal', component: RegistryView, name: "personal" },
  // { path: "/userInfo", component: UserInfoView, name: "userInfo" },
  // { path: "/change", component: ChangePassWord, name: "change" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // next();
  // return; //暂时不拦截登录

  // // 假设我们使用一个简单的布尔值来检查用户是否登录
  const isUserLoggedIn = Boolean(localStorage.getItem("userLoggedIn"));
  console.log(from, to, isUserLoggedIn, 222222);
  if (
    to.path !== "/login" &&
    to.path !== "/" &&
    to.path !== "/registry" &&
    to.path !== "/reset-pwd" &&
    !isUserLoggedIn
  ) {
    // 如果用户没有登录并且尝试访问非登录页面，则跳转到登录页面
    next("/login");
  } else {
    // 否则，继续正常的导航过程
    next();
  }
});

// 创建对象
const app = createApp(App);

// 全局错误处理
app.config.errorHandler = (err, instance, info) => {
  console.error("全局错误", err);
  console.error("出错组件实例", instance);
  console.error("错误信息", info);
};

/*全局引入*/
app.config.globalProperties.$http = http;
app.config.globalProperties.$api = api;
app.config.globalProperties.$common = common;

const filters = {
  moneyFormat(value) {
    return parseFloat(value).toFixed(2);
  },
};

app.provide("$filters", filters);

// 必须使用 nextTick，不然会有加载顺序问题，导致绑定失败
nextTick(() => {
  app.config.globalProperties.$filters = filters;
  // 配置全局对象
  app.config.globalProperties.$icons = Icons;
  // Antd 注入全部图标（这样注入之后，就可以全局直接使用 icon 组件，不需要每个页面去引入了）
  for (const key in Icons) {
    app.component(key, Icons[key]);
  }
});

app.use(Antd).use(router).mount("#app");
