<template>
  <LoginLayout>
    <div class="login-container">
      <div class="user-login-logo"></div>
      <div class="user-login-title">易标数据服务平台</div>
      <div class="login-form-container">
        <a-form
          v-if="activeKey === '1'"
          layout="vertical"
          hideRequiredMark
          :model="formState"
          @finish="handleFinish"
          @finishFailed="handleFinishFailed"
        >
          <a-form-item
            name="userPhone"
            :rules="[
              { required: true, message: '请输入手机号' },
              {
                message: '请输入正确的手机号',
                pattern: new RegExp(/^1[3456789]\d{9}$/),
              },
            ]"
            label="请输入帐号"
          >
            <a-input
              v-model:value="formState.userPhone"
              placeholder="账号"
            >
              <template #prefix><UserOutlined /></template>
            </a-input>
          </a-form-item>
          <a-form-item
            name="pwd"
            :rules="[
              { required: true, message: '请输入密码' },
              { message: '请输入至少6位字符', min: 6 },
            ]"
            label="请输入密码"
          >
            <a-input
              v-model:value="formState.pwd"
              type="password"
              placeholder="密码"
            >
              <template #prefix
                ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
              /></template>
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-form-item
              name="remember"
              no-style
            >
              <a-checkbox v-model:checked="formState.remember"
                >自动登录</a-checkbox
              >
            </a-form-item>
            <a-button
              type="link"
              @click="goChangePassword"
              >忘记密码？</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              class="login-button"
              type="primary"
              html-type="submit"
            >
              登录
            </a-button>
            <div
              class="extra"
              style="float: left"
              @click="goRegister"
            >
              还没有账号？
              <a href="">立即注册</a>
            </div>
          </a-form-item>
        </a-form>
        <a-form
          v-if="activeKey === '2'"
          layout="vertical"
          hideRequiredMark
          :model="formState"
          @finish="handleFinish"
          @finishFailed="handleFinishFailed"
        >
          <a-form-item
            name="userPhone"
            :rules="[
              { required: true, message: '请输入手机号' },
              {
                message: '请输入正确的手机号',
                pattern: new RegExp(/^1[3456789]\d{9}$/),
              },
            ]"
            label="请输入手机号"
          >
            <a-input
              v-model:value="formState.userPhone"
              placeholder="手机号"
            >
              <template #prefix><PhoneOutlined /></template>
            </a-input>
          </a-form-item>

          <a-form-item
            name="verifyCode"
            :rules="[{ required: true, message: '请输入验证码' }]"
            label="验证码"
          >
            <a-input-group compact>
              <a-input
                v-model:value="formState.verifyCode"
                placeholder="验证码"
                style="width: calc(100% - 120px)"
              >
                <template #prefix><VerifiedOutlined /></template>
              </a-input>
              <a-button
                style="width: 110px; margin-left: 10px"
                class="sms-btn"
                type="primary"
                >获取验证码</a-button
              >
            </a-input-group>
          </a-form-item>
          <a-form-item>
            <a-form-item
              name="remember"
              no-style
            >
              <a-checkbox v-model:checked="formState.remember"
                >自动登录</a-checkbox
              >
            </a-form-item>
          </a-form-item>
          <a-form-item>
            <a-button
              class="login-button"
              type="primary"
              html-type="submit"
            >
              登录
            </a-button>
            <div
              class="extra"
              style="float: left"
              @click="goRegister"
            >
              还没有账号？
              <a href="">立即注册</a>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </LoginLayout>
</template>

<script setup>
import api from "@/api/api";
import http from "@/api/http";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import LoginLayout from "../components/LoginLayout.vue";

const router = useRouter();

const activeKey = ref("1");

const handleTabChange = (key) => {
  console.log(key);
  activeKey.value = key;
};

let userPhone;
let pwd;
let remember = localStorage.getItem("remember") === "true";
if (remember === true) {
  userPhone = localStorage.getItem("userPhone");
  pwd = localStorage.getItem("pwd");
}

const formState = reactive({
  userPhone: userPhone || "",
  pwd: pwd ? encodeURIComponent(window.atob(pwd)) : "",
  remember: remember === true,
  verifyCode: "",
});
const handleFinish = (values) => {
  console.log(values, formState);

  http
    .post(api.LOGIN, values, {
      headers: {},
    })
    .then((response) => {
      // 处理响应
      console.log(response.data);
      let code = response.data.code;
      if (code == 200) {
        // common.showSuccess("登录成功")

        if (values.remember === true) {
          localStorage.setItem("userPhone", values.userPhone);
          localStorage.setItem(
            "pwd",
            window.btoa(encodeURIComponent(values.pwd))
          );
          localStorage.setItem("remember", true);
        }
        localStorage.setItem("token", response.data.data);
        localStorage.setItem("userLoggedIn", true);

        router.push({
          path: "/",
        });
      }
    });
};

const handleFinishFailed = (errors) => {
  console.log(errors);
};

const goRegister = (e) => {
  e.preventDefault();
  router.push({
    path: "/registry",
  });
};

const goChangePassword = (e) => {
  e.preventDefault();
  router.push({
    path: "/reset-pwd",
  });
};
</script>

<style>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-login-logo {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-bottom: 23px;
  background: url("~@/assets/user.png") no-repeat, none;
}
.user-login-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #3860f4;
  line-height: 30px;
  letter-spacing: 3px;
  text-align: left;
  font-style: normal;
  margin-bottom: 38px;
}
.login-button {
  width: 100%;
}
.ant-tabs {
}
.ant-tabs-nav::before {
  border-bottom: 0px !important;
}
.login-form-container {
  width: 100%;
}
.extra {
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.sms-btn {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #3860f4;
  text-align: left;
  font-style: normal;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background: rgba(56, 96, 244, 0.07);
  border-radius: 2px;
}
</style>
